import React from "react";
//
import imglyRemoveBackground from "@imgly/background-removal"
import MagicDropzone from "react-magic-dropzone";
//
import "./styles.css";


function calculateSecondsBetweenDates(startDate, endDate) {
    const milliseconds = endDate - startDate;
    const seconds = (milliseconds / 1000.0).toFixed(1);
    return seconds;
}

export default function ImageBg({setOutput, setSeconds}){
    const model = true;
    const [preview, setPreview] = React.useState("");

    const [isRunning, setIsRunning] = React.useState(false);
    const [startMs, setStartMs] = React.useState(Date.now());


    const startTimer = () => {
        setIsRunning(true);
        setStartMs(Date.now());
        setSeconds(0);
    };

    const stopTimer = () => {
        setIsRunning(false);
    };

    React.useEffect(() => {
        let interval = null;
        if (isRunning) {
            interval = setInterval(() => {
                setSeconds(calculateSecondsBetweenDates(startMs, Date.now()));
            }, 100);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isRunning, startMs, setSeconds]);

    const onDrop = (accepted, rejected, links) => {
        setPreview(accepted[0].preview || links[0]);
    };

    const public_path = '/static/js/' ; // the path assets are served from


    let config = {
        publicPath: public_path,
        progress: (key, current, total) => {
            console.log(`Key: ${key} - Current: ${current} - Total: ${total}`);
        }
    }

    // let image_src: ImageData | ArrayBuffer | Uint8Array | Blob | URL | string = ...;
    //
    // imglyRemoveBackground(image_src).then((blob: Blob) => {
    //     // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
    //     const url = URL.createObjectURL(blob);
    // })

    const onImageChange = e => {
        // const c = document.getElementById("canvas");
        // const ctx = c.getContext("2d");
        // console.log({e})
        // cropToCanvas(e.target, c, ctx);

        startTimer();
        imglyRemoveBackground(e.target.src, config).then((blob) => {
            // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
            const url = URL.createObjectURL(blob);
            // console.log({url})
            setOutput(url);
            stopTimer();
        })

    };

    return (
        <div className="Dropzone-page">
            {model ? (
                <>
                    <MagicDropzone
                        className="Dropzone"
                        accept="image/jpeg, image/png, .jpg, .jpeg, .png"
                        multiple={false}
                        onDrop={onDrop}
                    >
                        {preview ? (
                            <img
                                alt="upload preview"
                                onLoad={onImageChange}
                                className="Dropzone-img"
                                src={preview}
                            />
                        ) : (
                            "Choose or drop a file."
                        )}
                        <canvas id="canvas" />
                    </MagicDropzone>

                </>

            ) : (
                <div className="Dropzone">Loading model...</div>
            )}
        </div>
    );

}