import * as React from 'react';
//
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//
import ImageInput from './ImageInput';
import Result from "./Result";
import Timer from "./Timer";
import Download from "./Download";

export default function App() {
    const [output, setOutput] = React.useState(null);
    console.log({output});
    const [seconds, setSeconds] = React.useState(0);

    return (
        <React.Fragment>
            <CssBaseline />
            <Box sx={{backgroundColor:"#F9F9F9", height: '100vh'}}>
            <Container maxWidth="lg" >
                <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center" spacing={2}>
                    <Grid item xs>
                        <br/>
                        <Typography variant="h3" gutterBottom align="center">
                            AI Image Background Remover
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom align="center">
                            Conversion after first page load may take upto 50 seconds,
                            consecutive conversions will be done in 10 seconds.
                            Conversion in mobile devices will be slow.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <ImageInput setOutput={setOutput} setSeconds={setSeconds}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Timer seconds={seconds} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Result result={output} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Download url={output}/>
                </Grid>
            </Container>
            </Box>
        </React.Fragment>
    );
}

