import React from "react";
//
import Typography from '@mui/material/Typography';
//
import "./styles.css";

export default function Timer({seconds}) {

    return (
        <div className={"timer-section"}>
            {seconds && seconds !== 0 && seconds !== "0" ?
                <div className="timer-container">
                    <Typography variant="h3" gutterBottom>
                        {seconds}
                    </Typography>
                    {/*<span className="timer-val">{seconds}</span>*/}
                </div>
                : null}
        </div>

    )
}