import React from "react";
//
import "./styles.css";

export default function Result({result}) {

    return (
        <div className={"result-section"}>
            {result ? <div className="result-container">
                <img className="result-img"
                    alt="out preview"
                    src={result}
                />
            </div> :null}
        </div>

    )
}