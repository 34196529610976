import React from "react";
//
import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';

export default function Download({url}) {

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `output.png`;
        link.href = url;
        link.click();
    };

    return(
        <>
            { url ?
                <Button variant="contained" disableElevation fullWidth={true} size={"large"} onClick={onDownload} sx={{mx:2, my:10}}>
                    Download Output
                </Button>
                : null
            }


        </>
    )
}